<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="Transave Transaction Receipt"
      :pdf-quaility="2"
      :manual-pagination="false"
      pdf-content-width="100%"
      @hasStartedGeneration="hasGenerated($event)"
      :html-to-pdf-options="{
        filename: 'Transave Transaction Receipt',
        html2canvas: {
            scrollX: 0,
            scrollY: 0,
            scale: 5,
        },
        jsPDF: {
          unit: 'in',
          format: 'a6',
          orientation: 'portrait'
        },
      }"
      ref="transactionReceipt"
    >
      <section slot="pdf-content">
        <v-card
          class="pa-2 pr-2 pb-5"
        >
          <v-card-title style="justify-content: center;">
            <img :src="require('../../../../public/favicon.png')" height="50" alt="">
            <span style="margin-left: -5px; color: #C1A13D;">Tran</span>
            <span style="color: #171B70;">Save</span>
          </v-card-title>
          <v-toolbar style="display: flex; justify-content: center;" flat>TRANSACTION DETAILS</v-toolbar>
          <v-divider></v-divider>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <div class="pa-2">
                  <v-list-item-title style="float: left; font-size: 12px;">Receiver Account Name:</v-list-item-title>
                  <div class="text-right primary--text" style="float: right; font-size: 14px; color: #171B70;">{{ item.receiver_name }}</div>
                </div>
                <div class="pa-2">
                  <v-list-item-title style="float: left; font-size: 12px;">Destination Account Number:</v-list-item-title>
                  <span class="text-right primary--text" style="float: right; font-size: 14px; color: #171B70;">{{ item.receiver_account }}</span>
                </div>
                <div class="pa-2">
                  <v-list-item-title style="float: left; font-size: 12px;">Destination Bank:</v-list-item-title>
                  <span class="text-right primary--text" style="float: right; font-size: 14px; color: #171B70;">{{ item.bank_name }}</span>
                </div>
                <div class="pa-2">
                  <v-list-item-title style="float: left; font-size: 12px;">Source Account Name:</v-list-item-title>
                  <span class="text-right primary--text" style="float: right; font-size: 14px; color: #171B70;">{{ item.sender_name }}</span>
                </div>
                <div class="pa-2">
                  <v-list-item-title style="float: left; font-size: 12px;">Source Account Number:</v-list-item-title>
                  <span class="text-right primary--text" style="float: right; font-size: 14px; color: #171B70;">{{ item.sender_account }}</span>
                </div>
                <v-divider></v-divider>
                <div class="pa-2">
                  <div style="float: left; font-size: 12px;">Status:</div>
                  <span class="text-break primary--text" style="float: right; font-size: 14px; color: #171B70;">Successful</span>
                </div>
                <v-divider></v-divider>
                <div class="pa-2">
                  <v-list-item-title style="float: left; font-size: 12px;">Tran. Date:</v-list-item-title>
                  <span class="text-right primary--text" style="float: right; font-size: 14px; color: #171B70;">{{ item.created_at | tx_date }}</span>
                </div>
                <v-divider></v-divider>
                <div class="pa-2">
                  <v-list-item-title style="float: left; font-size: 12px;">Amount:</v-list-item-title>
                  <span class="primary--text" style="float: right; font-size: 14px; color: #171B70;">&#8358;{{ item.amount | formatPrice }}</span>
                </div>
                <v-divider></v-divider>
                <v-card-subtitle style="text-align: center; color: #333;">Generated By Transave</v-card-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </section>
    </vue-html2pdf>
    <v-container class="px-5 mb-10">
      <v-card class="">
        <div class="header pa-5 rounded-lg grey lighten-5 elevation-0">
          <v-row>
            <v-col cols="8" md="10">
              <div class="title-m primary--text text-h5 font-weight-medium">Transaction History</div>
            </v-col>
            <v-col cols="4" md="2">
              <v-btn
                block
                class="mb-0 primary"
                outlined
                dark
                large
                @click="$refs.export.openDialog()"
                >Export</v-btn
              >
            </v-col>
          </v-row>
          
          <v-row justify="center" align="center">
            <v-col cols="12" md="6" sm="6" class="pb-0">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                placeholder="Search transaction history"
                solo outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col class="pb-0">
              <v-select
                placeholder="Filter transaction type"
                class=""
                v-model="form.filter"
                :items="[
                  { text: 'All', value: 'All' },
                  { text: 'Credit', value: 'Credit' },
                  { text: 'Debit', value: 'Debit' },
                ]" solo outlined
                dense
                @change="filterTransactions()"
              ></v-select>
            </v-col>
            <v-col align-self="start">
              <v-btn
                color="primary"
                class="elevation-0"
                @click="openFilter()"
              >Filter</v-btn>
            </v-col>
          </v-row>
        </div>

        <v-data-table
          class="mt-0"
          :headers="headers"
          :items="history"
          :search="search"
          item-key="id"
          :items-per-page="10"
          :loading="loader"
        >
          <!-- <template slot="no-data"> No Transactions yet </template> -->
          <template v-slot:[`item.number`]="{ index }">
            <div>{{ index + 1 }}</div>
          </template>
          <template v-slot:[`item.credit`]="{ item }">
            <div>&#8358;{{ item.type === 'Credit' ? item.amount : 0 | formatPrice }}</div>
          </template>
          <template v-slot:[`item.debit`]="{ item }">
            <div>&#8358;{{ item.type === 'Debit' ? item.amount : 0 | formatPrice }}</div>
          </template>
          <template v-slot:[`item.user_balance_before`]="{ item }">
            <div>&#8358;{{ item.user_balance_before | formatPrice }}</div>
          </template>
          <template v-slot:[`item.user_balance_after`]="{ item }">
            <div>&#8358;{{ item.user_balance_after | formatPrice }}</div>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <div style="width: 100px">{{ item.created_at | date_time }}</div>
          </template>
          <template v-slot:[`item.activity`]="{ item }">
            <div>
              {{ (transfers.includes(item.activity) && item.receiver_name !== null) ? item.activity +
              (item.type === 'Debit' && item.receiver_name ? ' to ' + item.receiver_name + ',' : (item.type === 'Credit' &&
              item.sender_name ? ' from ' + item.sender_name + ',' : '')) + (item.type === 'Debit' && item.receiver_account ?
              ' ACC: ' + item.receiver_account + ',' : (item.type === 'Credit' && item.sender_account ?
              ' ACC: ' + item.sender_account + ',' : '')) + (item.bank_name ? ' BANK: ' +
              item.bank_name : '') : item.activity }}
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn color="primary" small @click="exportHistory(item)"
            >Download</v-btn
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <transaction-filter ref="filter"></transaction-filter>
    <Statement ref="export"/>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import TransactionFilter from '@/components/transactions/filter';
import Statement from '@/components/transactions/statement';
  export default {
    components: {
      TransactionFilter,
      Statement,
    },

    data() {
      return {
        layout: 'dashboard',
        transition: 'esther default',
        menu1: false,
        menu: false,
        start_date: new Date().toISOString().substr(0, 10),
        end_date: new Date().toISOString().substr(0, 10),
        dialog: false,
        headers: [
          { text: 'S/N', value: 'number', orderable: false, sortable: false},
          { text: "Credit", value: "credit" },
          { text: "Debit", value: "debit" },
          { text: "Balance Before", value: "user_balance_before"},
          { text: "Balance After", value: "user_balance_after"},
          { text: "Description", value: "activity"},
          { text: "Date", value: "created_at" },
          { text: "Receipt", value: "action" },
        ],
        search: '',
        form: {},
        history: [],
        loader: false,
        transfers: ['Wallet Transfer', 'Bank Transfer'],
        item: {},
      }
    },

    
    computed: {
      ...mapGetters("auth", ["user", "loan_balance"]),

      account_number () {
        if (this.user.accounts) {
          return this.user.accounts.find(
            x => x.account_name === 'Wallet ID'
          ).account_number
        }
      },
    },
    
    mounted() {
      this.transactionHistory()
    },

    methods: {
      ...mapActions('auth', [
        'transaction_details',
        'filter_transactions'
      ]),

      async transactionHistory() {
        this.loader = true;
        try {
          const response = await this.transaction_details();
          this.history = response.data.transactions;
          this.loader = false;
          this.history.forEach(item => {
            item.receiver_name = (item.payload) ? item.payload.receiver_name ?? item.payload.account_name : '';
            item.bank_name = (item.payload) ? item.payload.bank_name ?? item.payload.bank : '';
            item.sender_name = (item.payload) ? item.payload.sender_name ?? '' : '';
            item.receiver_account = (item.payload) ? item.payload.recipient_account ?? item.payload.receiver_account : '';
            item.sender_account = (item.payload) ? item.payload.sender_account ?? item.payload.recipient_account : '';
          });
        } catch (err) {
          
        }
      },

      async filterTransactions() {
        this.loader = true;
        try {
          let response = await this.filter_transactions(this.form);
          this.history = response.data.transactions;
          this.loader = false
        } catch (error) {
          this.loader = false;
          if (error.response.status === 500) {
            this.$toast.error('Something went wrong, unable to process request.');
          } else {
            this.$toast.error(error.response.data.message);
          }
        }
      },

      openFilter() {
        this.$refs.filter.openDialog();
      },

      async exportHistory(item) {
        this.item = Object.assign({}, item);
        this.$refs.transactionReceipt.generatePdf()
      },
    },
  }
</script>
