<template>
  <div>
    <v-dialog
      max-width="450"
      v-model="dialog"
    >
      <v-card
        class="pa-4"
        flat
        style="min-height: 200px;"
      >
        <v-card-subtitle class="primary--text text-center">
          Filter Transactions by date
        </v-card-subtitle>
        <v-form lazy-validation ref="form">
          <v-row>
            <v-col>
              <v-card-subtitle class="primary--text py-0 px-0">Start Date</v-card-subtitle>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    append-icon="event_note"
                    outlined
                    dense
                    :value="form.start_date"
                    placeholder="Start Date"
                    readonly
                    v-on="on"
                    color="primary"
                    style="background: #fff; height: 40px"
                    :rules="[(v) => !!v || 'Please select a date']"
                  ></v-text-field>
                </template>
                <v-date-picker
                  light
                  v-model="form.start_date"
                  @change="menu = false"
                  color="primary"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col>
              <v-card-subtitle class="primary--text py-0 px-0">End Date</v-card-subtitle>
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    append-icon="event_note"
                    outlined
                    dense
                    :value="form.end_date"
                    placeholder="End Date"
                    readonly
                    v-on="on"
                    color="primary"
                    style="background: #fff; height: 40px"
                    :rules="[(v) => !!v || 'Please select a date']"
                  ></v-text-field>
                </template>
                <v-date-picker
                  light
                  v-model="form.end_date"
                  @change="menu1 = false"
                  color="primary"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions class="px-0 mt-3">
          <v-row>
            <v-col>
              <v-btn
                block
                elevation="0"
                color="grey lighten-3"
                class="primary--text"
                @click="closeDialog()"
              >Close</v-btn>
            </v-col>
            <v-col>
              <v-btn
                elevation="0"
                block
                color="primary"
                :loading="loading"
                loading-text="Please wait"
                @click="$refs.form.validate() ? filterResult() : null"
              >Filter</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  export default {
    data: () => {
      return {
        dialog: false,
        menu: false,
        menu1: false,
        form: {},
        loading: false,
        user_id: null,
        terminal_id: null,
      }
    },

    methods: {
      ...mapActions('auth', [
        'filter_transactions',
      ]),

      openDialog() {
        this.dialog = true;
      },

      closeDialog() {
        this.dialog = false;
      },

      async filterResult() {
        this.loading = true;
        try {
          let response = await this.filter_transactions(this.form);
          this.$parent.history = response.data.transactions.transactions;
          this.loading = false;
          this.$toast.success('Transactions filtered');
          this.closeDialog();
          this.$refs.form.reset();
          this.form = {};
        } catch (error) {
          this.loading = false;
          this.$toast.error(error.response.data.message)
        }
      }
    }
  }
</script>